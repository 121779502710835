@import url("Fonts.scss");

$primary: #7e81f7;
$secondary: #f1f1f4;
$success: #5cb85c;
$danger: #ff0000;
$body-color: #141516;
$font-family-base: "SF Pro Text", sans-serif;
$headings-font-family: "SF Pro Text", sans-serif;
$input-focus-border-color: #c5d3e1;

:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --border: #1ed387;
  --border-light: #b1b1b1;
  --body: #{$body-color};
  --gray: #f8f8ff;
  --white: #ffffff;
  --link: #3678b7;
  --link-hover: #225f99;
  --success: #{$success};
  --danger: #{$danger};
  --font: #{$font-family-base};
  --font-headings: #{$headings-font-family};
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  @media (min-width: 767px) {
    max-width: 706px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1090px;
  }

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.text-primary {
  color: var(--primary);
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
a {
  &.btn {
    text-decoration: none;
  }
}

.btn {
  font-family: var(--font);
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 6px;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid transparent;
  cursor: pointer;
  @media (max-width: 355px) {
    padding: 8px 12px;
    font-size: 0.875rem;
  }
  &:focus,
  &:active {
    outline: none;
  }
  &:focus-visible {
    outline: 1px dotted #212529;
    outline-offset: 0.25rem;
  }
  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
    pointer-events: none;
  }
  &.btn-primary {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
    &:hover {
      background-color: var(--body);
      border-color: var(--body);
    }
  }
  &.btn-secondary {
    color: var(--white);
    background-color: var(--secondary);
    border-color: var(--secondary);
    &:hover {
      background-color: var(--primary);
      border-color: var(--primary);
    }
  }
}
