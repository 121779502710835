header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #f1f1f4;
  background: var(--white);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
  .headerSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    img {
      @media (max-width: 768px) {
        height: 38px;
      }
    }
  }
  .right-nav {
    i {
      font-size: 22px;
      line-height: 1;
    }
  }
}
footer {
  background-color: var(--body);
  padding-top: 24px;
  padding-bottom: 24px;
  .footer {
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 24px;
      border-bottom: 1px solid #323334;
    }
    &-logo {
      img {
        @media (max-width: 768px) {
          height: 35px;
        }
      }
    }
    &-insta {
      a {
        display: flex;
        align-items: center;
        gap: 6px;
        text-decoration: none;
      }
      img {
        @media (max-width: 768px) {
          height: 20px;
        }
      }
      span {
        color: var(--primary);
        text-decoration: none;
        font-weight: 600;
        user-select: none;
        font-size: 16px;
      }
    }
  }
  .copyRight {
    color: var(--white);
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding-top: 24px;

    @media (max-width: 355px) {
      font-size: 12px;
    }
  }
}
