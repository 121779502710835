section {
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 1100px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.banner {
  text-align: center;
  &-content {
    display: flex;
    flex-direction: column;
    gap: 24px 0;
    max-width: 869px;
    margin: 0 auto 56px;
    h1 {
      font-size: 72px;
      font-style: normal;
      font-weight: 700;
      line-height: 80px; /* 111.111% */
      letter-spacing: -3.312px;
      @media (max-width: 1100px) {
        font-size: 42px;
        line-height: 48px; /* 114.286% */
        letter-spacing: -1.932px;
        max-width: 510px;
        margin: 0 auto;
      }
      @media (max-width: 767px) {
        font-size: 34px;
        line-height: 40px; /* 117.647% */
        letter-spacing: -1.564px;
      }

      @media (max-width: 355px) {
        font-size: 30px;
      }

      .highlight {
        color: var(--primary);
      }
      span {
        position: relative;
        img {
          position: absolute;
          bottom: 5px;
          left: 0;
          z-index: -1;
        }
      }
    }
    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 160% */
      letter-spacing: -0.32px;
      @media (max-width: 1100px) {
        font-size: 18px;
        line-height: 28px; /* 155.556% */
        letter-spacing: -0.288px;
      }
    }
    &-btn {
      display: flex;
      justify-content: center;
      gap: 12px;
    }
  }
}

.features {
  background: var(--gray);
  &-card {
    margin-bottom: 64px;
    .badge span {
      border-color: #fe7b1d;
    }
  }
  &-list {
    display: flex;
    gap: 20px;
    @media (max-width: 1100px) {
      flex-wrap: wrap;
    }
    &-card {
      width: 100%;
      background-color: var(--white);
      display: flex;
      padding: 24px 16px;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 12px;
      text-align: center;
      @media (max-width: 1100px) {
        min-width: calc(50% - 42px);
      }
      @media (max-width: 600px) {
        min-width: 100%;
      }
      h3 {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 133.333% */
        letter-spacing: -1.104px;
        text-transform: capitalize;
      }
      p {
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.256px;
      }
    }
  }
}

.report {
  &-card .badge span {
    border-color: var(--border);
  }
}
.exercise {
  background: var(--gray);
  &-card .badge span {
    border-color: #e34545;
  }
}
.trainers {
  &-card {
    .badge span {
      border-color: var(--primary);
    }
    .detailsCard-content {
      max-width: 511px;
    }
  }
}
.mobileApp {
  background-color: var(--primary);
  background-image: url("../../Assets/mobileAppBG.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 10px;
  padding-top: 26px;

  @media (max-width: 1100px) {
    padding-bottom: 40px;
    padding-top: 64px;
  }

  @media (max-width: 700px) {
    background-size: 100% 100%;
  }
  .mobileAppSec {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1100px) {
      gap: 0 30px;
    }
    @media (max-width: 700px) {
      flex-direction: column;
      gap: 64px 0;
    }
    &-content {
      max-width: 443px;
      @media (max-width: 1100px) {
        max-width: 309px;
      }

      h2 {
        color: var(--white);
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 125% */
        letter-spacing: -2.208px;
        margin-bottom: 32px;
        @media (max-width: 1100px) {
          font-size: 32px;
          line-height: 36px; /* 112.5% */
          letter-spacing: -1.472px;
        }

        @media (max-width: 700px) {
          font-size: 28px;
          line-height: 32px; /* 114.286% */
          letter-spacing: -1.288px;
        }
      }
      &-btn {
        display: flex;
        gap: 0 12px;
      }
    }
    &-image {
      @media (max-width: 1100px) {
        max-width: 333px;
        width: 100%;
      }
    }
  }
}
