@font-face {
    font-family: 'SF Pro Text';
    src: url('Assets/fonts/SFProText-BoldItalic.eot');
    src: url('Assets/fonts/SFProText-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Assets/fonts/SFProText-BoldItalic.woff2') format('woff2'),
        url('Assets/fonts/SFProText-BoldItalic.woff') format('woff'),
        url('Assets/fonts/SFProText-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('Assets/fonts/SFProText-HeavyItalic.eot');
    src: url('Assets/fonts/SFProText-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('Assets/fonts/SFProText-HeavyItalic.woff2') format('woff2'),
        url('Assets/fonts/SFProText-HeavyItalic.woff') format('woff'),
        url('Assets/fonts/SFProText-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('Assets/fonts/SFProText-Bold.eot');
    src: url('Assets/fonts/SFProText-Bold.eot?#iefix') format('embedded-opentype'),
        url('Assets/fonts/SFProText-Bold.woff2') format('woff2'),
        url('Assets/fonts/SFProText-Bold.woff') format('woff'),
        url('Assets/fonts/SFProText-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('Assets/fonts/SFProText-Heavy.eot');
    src: url('Assets/fonts/SFProText-Heavy.eot?#iefix') format('embedded-opentype'),
        url('Assets/fonts/SFProText-Heavy.woff2') format('woff2'),
        url('Assets/fonts/SFProText-Heavy.woff') format('woff'),
        url('Assets/fonts/SFProText-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('Assets/fonts/SFProText-LightItalic.eot');
    src: url('Assets/fonts/SFProText-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Assets/fonts/SFProText-LightItalic.woff2') format('woff2'),
        url('Assets/fonts/SFProText-LightItalic.woff') format('woff'),
        url('Assets/fonts/SFProText-LightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('Assets/fonts/SFProText-Medium.eot');
    src: url('Assets/fonts/SFProText-Medium.eot?#iefix') format('embedded-opentype'),
        url('Assets/fonts/SFProText-Medium.woff2') format('woff2'),
        url('Assets/fonts/SFProText-Medium.woff') format('woff'),
        url('Assets/fonts/SFProText-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('Assets/fonts/SFProText-Light.eot');
    src: url('Assets/fonts/SFProText-Light.eot?#iefix') format('embedded-opentype'),
        url('Assets/fonts/SFProText-Light.woff2') format('woff2'),
        url('Assets/fonts/SFProText-Light.woff') format('woff'),
        url('Assets/fonts/SFProText-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('Assets/fonts/SFProText-MediumItalic.eot');
    src: url('Assets/fonts/SFProText-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Assets/fonts/SFProText-MediumItalic.woff2') format('woff2'),
        url('Assets/fonts/SFProText-MediumItalic.woff') format('woff'),
        url('Assets/fonts/SFProText-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('Assets/fonts/SFProText-RegularItalic.eot');
    src: url('Assets/fonts/SFProText-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('Assets/fonts/SFProText-RegularItalic.woff2') format('woff2'),
        url('Assets/fonts/SFProText-RegularItalic.woff') format('woff'),
        url('Assets/fonts/SFProText-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('Assets/fonts/SFProText-Regular.eot');
    src: url('Assets/fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
        url('Assets/fonts/SFProText-Regular.woff2') format('woff2'),
        url('Assets/fonts/SFProText-Regular.woff') format('woff'),
        url('Assets/fonts/SFProText-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('Assets/fonts/SFProText-Semibold.eot');
    src: url('Assets/fonts/SFProText-Semibold.eot?#iefix') format('embedded-opentype'),
        url('Assets/fonts/SFProText-Semibold.woff2') format('woff2'),
        url('Assets/fonts/SFProText-Semibold.woff') format('woff'),
        url('Assets/fonts/SFProText-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('Assets/fonts/SFProText-SemiboldItalic.eot');
    src: url('Assets/fonts/SFProText-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('Assets/fonts/SFProText-SemiboldItalic.woff2') format('woff2'),
        url('Assets/fonts/SFProText-SemiboldItalic.woff') format('woff'),
        url('Assets/fonts/SFProText-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

