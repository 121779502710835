.detailsCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 74px;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 30px 0;
  }
  &.reverse {
    @media (min-width: 767px) {
      flex-direction: row-reverse;
    }
  }
  &-content {
    width: 100%;
    max-width: 566px;
    display: flex;
    flex-direction: column;
    gap: 12px 0;

    @media (max-width: 767px) {
      max-width: 100%;
    }
    .badge {
      display: flex;
      span {
        padding: 2px 8px;
        border-radius: 6px;
        border: 1px solid var(--border);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        letter-spacing: -0.736px;
        text-transform: capitalize;
      }
    }
    h2 {
      color: #000;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 125% */
      letter-spacing: -2.208px;
      @media (max-width: 1100px) {
        font-size: 32px;
        line-height: 36px; /* 112.5% */
        letter-spacing: -1.472px;
      }
    }
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.256px;
    }
  }
}
