html,
body,
* {
  padding: 0;
  margin: 0;
}
*,
:before,
:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
} /* we'll restore bullets as needed for content */

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

small {
  font-size: 0.8rem;
}

label,
input[type="button"],
input[type="submit"],
input[type="file"],
button {
  cursor: pointer;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

html {
  font-size: 16px;
}

/* ———— END THE GENERIC RESETS ———— */

body {
  font-family: var(--font);
  font-size: 1rem;
  line-height: 1;
  color: var(--body);
}
